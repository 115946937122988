import React, {Component, useEffect, useState} from 'react';
import HeaderComponent from "../../components/header/header.component";
import HomepageGalleryComponent from "../../components/homepage-gallery/homepage-gallery.component";
import headerImage from "../../img/Cancer-Care-Images/occ-hero.png";
import ctaImage from "../../img/Cancer-Care-Images/CTA_Updated.png";
import HOMEPAGE_DATA from "./homepage.data";
import CTAComponent from "../../components/call-to-action/cta-footer.component";
import Interior5050Component from "../../components/interior-50-50/interior-50-50.component";
import {hideMask} from "../../components/navigation/navigation.component";
import './homepage.styles.scss'
import CardListComponent from "../../components/Card_List_Optum/card-list";

import firebase from '../../firebase.config';

function HomePage() {
    const DATA = HOMEPAGE_DATA;

    const [clinicalTrials, setClinicalTrials] = useState([]);

    useEffect( () => {
    const fetchClinicalTrials = async () => {
        const db = firebase.firestore()
        const data = await db.collection("clinicalTrials").get()
        setClinicalTrials(data.docs.map( doc => doc.data()))
    }
    fetchClinicalTrials()
}, [])


        return(
            <>
                <main className='page-body' onClick={hideMask}>
                        <HeaderComponent imageUrl={headerImage}
                                         title="Nevada's leading cancer care specialists"
                                         innerCopy="Integrated care from a team of compassionate providers"
                                         topText="Optum Care Cancer Care"
                                         isTitleDash={true}
                                         shouldHideHomeButton={true}
                        />
                        <div className="container-large">
                            <HomepageGalleryComponent/>
                        </div>

                        {
                            DATA.interiorSections.map( ({ id, ...otherSectionProps }) => (
                                <Interior5050Component key={id} {...otherSectionProps} />
                            ))
                        }
                       <div className="cta-container">
                           <CTAComponent
                               imageUrl={ctaImage}
                               htmlCopy={DATA.ctaInfo.htmlCopy}
                           />
                       </div>
                </main>
            </>
        );
}

export default HomePage;
