import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import heroImage from '../../../img/pay-bills-hero.png';
import ctaImage from '../../../img/pay-bill-1.png';
import CTAComponent from "../../../components/call-to-action/cta-footer.component";
import {Helmet} from "react-helmet";
import {hideMask} from "../../../components/navigation/navigation.component";
import  pdf1 from "../../../img/documents/Ortho-Spine-release_info.pdf";
import  pdf2 from "../../../img/documents/Ortho-Spine-release_info.pdf";

const pageTitle = "How to pay your bill";

const htmlCTACopy = `<h4>Pay by phone</h4>
            <p>To pay your bill over the phone, please call <a href="tel:+17027248877">1-702-724-8877</a> Option 7</p>
            <br>
            <h4>Pay by mail</h4>
            Send a check or money order to:
            <br>
            Optum Care Cancer Care<br>
            PO Box 204646 Dallas, TX 75320-4646
            <br><br>
            Please make check or money order payable to
            Optum Care Cancer Care.
            <br><br>
            <h4>Pay your bill in person</h4>
            Visit our location at: <br>
            4750 W Oakey Blvd Ste 4C <br>
            Las Vegas, NV 89102  <br> 
            Located inside the Southwest Medical building, on the 4th floor. <br> 
            Phone <a href="tel:+17027248877">1-702-724-8877</a>    `
;

const HowToPayYourBillPage = () => (
    <>
        <Helmet>
            <title>{pageTitle} | Optum Care Cancer Care</title>
        </Helmet>
        <main className='page-body' onClick={hideMask}>
            <HeaderComponent imageUrl={heroImage}
                             title="Patient Resources"
            />
            <div className='row justify-content-around'>
                <div className="col-md-8 col-sm-12">
                    <p className="text-centered">
                    Payments to Optum Care Cancer Care can be made by phone, by mail, or in-person.
                    </p>
                </div>
            </div>
            <div className="container-large">
                <CTAComponent
                    imageUrl={ctaImage}
                    imageHeight='550'
                    htmlCopy={htmlCTACopy}
                />
            </div>
            <div className='row justify-content-around mt-4'>
                <div className="col-md-8 col-sm-12">
                    <h3 className="text-centered mt-3">Request your Medical Records </h3><br></br>
                    <p className="text-centered">How to see the information we’ve recorded about your health. Here’s what you need to know about gaining access to your protected health information (PHI). </p>
                    <div className="text-centered">
                        <p><strong>Request form for your medical records</strong></p>
                        <p>For information or help with your request for your Optum Care Cancer Care records, please call <a href="tel:+17027248877">1-702-724-8877</a></p>
                        <p>To get a copy of your Optum Care Cancer Care records: <a href={pdf1} target="blank">Request for Medical Records</a></p>
                        <p>To ask for your medical records from another provider: <a href={"#"}>Request for Medical Records</a></p>
                        <p>Please allow 7–10 days for us to respond to your request.</p>
                    </div>
                </div>
            </div>
        </main>
    </>
)

export default HowToPayYourBillPage;
