import React from 'react';
import './locations-detail-card.styles.scss'

class LocationsDetailCardComponent extends React.Component {

    render() {
        return (
            <>
                <div className="locations-detail-card-container">
                    {/*<div className="">*/}
                    {/*    <iframe className="googleMap" width="300" height="320" loading="lazy" allowFullScreen*/}
                    {/*            src="https://www.google.com/maps/embed/v1/place?q=4750%20W%20Oakey%20Blvd.%2C%20STE.%204C%20Las%20Vegas%2C%20NV%2089012&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15" title="Map of our locations"></iframe>*/}
                    {/*</div>*/}
                </div>
            </>
        );
    }
}

export default LocationsDetailCardComponent;
