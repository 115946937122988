import React from 'react';
import HeaderComponent from "../../../components/header/header.component";
import Image2Paragraphs from "../../../components/image-with-paragraphs/image-with-paragraphs.component";
import CTAComponent from "../../../components/call-to-action/cta-footer.component";
import NUTRITION_DATA from "./nutrition.data";

import ctaImage from '../../../img/Cancer-Care-Images/CTA_Updated.png'
import heroImage from '../../../img/figma/nutrition.png';
import ButtonsDirectoryComponent from "../../../components/buttons-directory/buttons-directory.component";
import {Helmet} from "react-helmet";
import {hideMask} from "../../../components/navigation/navigation.component";
import SingleParagraph from "../../../components/single-paragraph/single-paragraph";


class NutritionPage extends React.Component {
    pageTitle = "Nutrition";
    state = NUTRITION_DATA;
    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.pageTitle} | Optum Care Cancer Care</title>
                </Helmet>
                <main className='page-body' onClick={hideMask}>
                    <HeaderComponent imageUrl={heroImage} title={this.pageTitle} />
                    <div className='row justify-content-around'>
                        <div className="col-md-8 col-sm-12">
                            <p className="text-centered">
                            
                            </p>
                        </div>
                    </div>
                    
                    {
                        this.state.sections.map(({id, ...otherSectionProps}) => (
                            <SingleParagraph key={id} {...otherSectionProps} />
                        ))
                    }
                    <div className="cta-container">
                            <CTAComponent
                                imageUrl={ctaImage}
                                htmlCopy={this.state.ctaInfo.htmlCopy}
                            />
                    </div>
                </main>
                
            </div>
        );
    }   
}

export default NutritionPage;