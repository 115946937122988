import React from 'react';
import {connect} from "react-redux";
import {Route} from "react-router";
import {selectIndividualProvider} from "../../redux/provider/provider.selectors";
import './providers-template.styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBookReader,
    faBriefcaseMedical, faCertificate, faClock, faGlobeAmericas,
    faGraduationCap,
    faMapMarkerAlt, faPhoneAlt,
    faUserMd, faStethoscope
} from "@fortawesome/free-solid-svg-icons";
import {Helmet} from "react-helmet";
import {Row} from "react-bootstrap";
import LocationsDetailCardComponent from "../../components/locations-detail-card/locations-detail-card.component";
import {hideMask} from "../../components/navigation/navigation.component";

import locationsData from '../locations/locations.data';

const ProviderTemplatePage = ({ provider, match }) => {
    return (
        <>
            <Helmet>
                <title>{provider.providerName} | Optum Care Cancer Care</title>
            </Helmet>
            <main className='page-body' onClick={hideMask}>
                <section className="container-fluid block-container-3 breathing-room provider-single">
                    <div className="row justify-content-around">
                        <a href="/providers" className="provider-single-breadcrumb">Providers</a>
                        <div className=" col-md-8 col-sm-12 no-padding info">
                            <h1>{provider.providerName}, {provider.providerTitle}</h1>
                            <div className= "border-bottom">
                                <span><FontAwesomeIcon className="mr-3" icon={faStethoscope}/>
                                    {
                                        provider.specialty.map((specialty, index) => {
                                            if (index === 0) {
                                                return (
                                                    <b>{specialty}</b>
                                                )
                                            } else {
                                                return (
                                                    <span>, <b>{specialty}</b></span>
                                                )
                                            }
                                        })
                                    }
                                </span>
                            </div>
                            <h2 className="mt-5">Overview</h2>
                            <p>
                                <div dangerouslySetInnerHTML={{__html: provider.providerBio}}/>
                            </p>
                            {provider.providerPhilosophy.length > 0 &&
                            <div>
                                <hr/>
                                <h4 className='providerPhilosophy-heading'>Philosophy</h4>
                                <div>
                                    {
                                        provider.providerPhilosophy.map(function (item, i) {
                                            return (
                                                <p key={i}
                                                   className="providerPhilosophy-section">{provider.providerPhilosophy[i]}</p>
                                            )
                                        })
                                    }
                                </div>
                            </div>}
                            <div className="border-bottom pb-5"></div>
                            {provider.organizations.length > 0 &&
                            <div className="mb-3 organizations-section">
                                <hr/>
                                <h3 className='organizations-heading'>Professional Organizations</h3>
                                <ul>
                                    {
                                        provider.organizations.map(function (item, i) {
                                            return (
                                                <li key={i}>{provider.organizations[i]}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>}
                            <div className="row border-bottom pb-5 w-100">
                                <div className="col-lg-12 col-xl-6 col-md-12 mt-5 no-padding">
                                    <h3>Qualifications</h3>
                                    {
                                        provider.qualifications.map(function (item, i) {
                                            return (
                                                <>
                                                    <div className="row w-100">
                                                        <div className="col-1 no-padding">
                                                            {(() => {
                                                                switch (item.type) {

                                                                    case 'Medical School':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1"
                                                                                             icon={faGraduationCap}/>
                                                                        )
                                                                    case 'Internship':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1"
                                                                                             icon={faBriefcaseMedical}/>
                                                                        )
                                                                    case 'Residency':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1"
                                                                                             icon={faUserMd}/>
                                                                        )
                                                                    case 'Fellowship':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1"
                                                                                             icon={faBookReader}/>
                                                                        )
                                                                    case 'Board certifications':
                                                                        return (
                                                                            <FontAwesomeIcon className="mr-1"
                                                                                             icon={faCertificate}/>
                                                                        )
                                                                    default:

                                                                        return (

                                                                            <div>Nothing</div>

                                                                        )

                                                                }
                                                            })()}
                                                        </div>
                                                        <div key={i} className="media col-11 pr-3 pl-0">
                                                            <p>
                                                                <b>{item.type}</b>
                                                                <br/>
                                                                {item.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-lg-12 col-xl-6 col-md-12 mt-5 no-padding">
                                    {provider.professionalAssociations.length > 0 &&
                                    <div>
                                        <h1>Professional Associations</h1>
                                        <ul>
                                            {
                                                provider.professionalAssociations.map((association) => (
                                                    <li>{association}</li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className="row w-100">
                                <div className="col-lg-6 mt-5 no-padding">
                                    <h1 className="">Languages</h1>
                                    <p><FontAwesomeIcon className="mr-3" icon={faGlobeAmericas}/>I
                                        speak {
                                            provider.languages.map((language, index) => {
                                                if (index === 0) {
                                                    return (
                                                        <b>{language}</b>
                                                    )
                                                } else {
                                                    return (
                                                        <span> and <b>{language}</b></span>
                                                    )
                                                }
                                            })
                                        }
                                    </p>
                                </div>
                                <div className="col-lg-6 mt-5 no-padding">
                                    <h1 className="">Gender</h1>
                                    <p>
                                        <FontAwesomeIcon className="mr-3" icon={faUserMd}/>
                                        {provider.sex}
                                    </p>
                                </div>
                            </div>
                            {provider.publicationsAndResearch.length > 0 &&
                                <div className="row mt-5 border-top w-100">
                                    <div className="col-lg-12 mt-5 no-padding">
                                        <h3>Publications and research</h3>
                                        <ul>
                                            {
                                                provider.publicationsAndResearch.map((item, index) => {
                                                    return (
                                                        <li key={index}><b>{item}</b></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 right-section-info">
                            <Row>
                                <img src={provider.providerInteriorImage}
                                     alt={"Image of Provider - " + provider.providerName}/>
                            </Row>
                            <Row>
                                <h3 className="w-100 mt-5">Locations ({ provider.providerLocations.length})</h3>
                                {
                                    provider.providerLocations.map(function (item,index) {
                                        return (
                                                <>
                                                    <div className={'locations-detail-card-container mb-4 py-3'}>


                                                        <p><b>
                                                            { locationsData.locations[index].id &&
                                                        locationsData.locations[index].title
                                                        }</b>
                                                        </p>
                                                        <p>
                                                            <FontAwesomeIcon className="mr-1 " icon={faMapMarkerAlt}/>

                                                            <a href={locationsData.locations[index].linkUrl} className="" target="_blank">
                                                           {locationsData.locations[index].address1}<br/>
                                                                <span className="ml-3"> {locationsData.locations[index].address2} </span>
                                                            </a>
                                                        </p>
                                                        <p>
                                                        <FontAwesomeIcon className="mr-1" icon={faPhoneAlt}/>
                                                        <a href={`tel:${locationsData.locations[index].phone}`} className="underline">
                                                            {locationsData.locations[index].phone}
                                                        </a>
                                                        </p>


                                                    </div>
                                                </>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </div>

                    <Route path={`${match.path}/:providerId`}/>
                </section>
            </main>
        </>
    )
};

const mapToStateProps = (state, ownProps) => ({
    provider: selectIndividualProvider(ownProps.match.params.providerId)(state)
})

export default connect(mapToStateProps)(ProviderTemplatePage);
