import cancerswetreat1 from '../../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../../img/cancers-we-treat-4.png';

const NUTRITION_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>Our registered dietitian provides nutrition counseling before, during and after treatment. We offer personalized nutritional support to help our patients maintain their strength and ideal body weight in order to reduce the risk of side effects and complications during cancer treatment. Certain cancer treatments can cause nausea, digestive issues, weight loss, or reduced appetite. Malnutrition can affect a patient’s ability to tolerate treatment. Our nutritional support expert can help patients keep a well-balanced diet that is nourishing and in alignment with their cancer treatment goals. The proper diet can boost the immune system and support a patient's recovery.  </p>
                        <p>As part of our resources, we also offer access to nutritional information, cookbooks, and recipes inside our Patient Resource Library so that patients and their families can learn about foods and diets that can assist in cancer treatment and recovery. </p>`,
            sectionId1: "nutrition-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default NUTRITION_DATA; 