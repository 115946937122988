import cancerswetreat1 from '../../img/Cancer_Center_Building_Photos/OC_Cancer_Center/building_pic_1.png';
import cancerswetreat2 from '../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../img/cancers-we-treat-4.png';
import patientresourcesbreak1 from '../../img/Cancer_Center_Building_Photos/OC_Cancer_Center/cancer_building_interior_1.png';
import image1 from "../../img/Cancer-Care-Images/patient_interior_5050.png";

const PATIENT_RESOURCES_GENERAL_DATA = {
    sections: [
        {
            imageUrl: cancerswetreat1,
            htmlCopy1: ``,
            sectionId1: "patient-resources-1",
            cssClass: "",
            id: 1
        }

    ],
    textSection1: [
        {
            title2: "Financial assistance",
            bodyTextLine1: "The cost of treatment and cancer care can be overwhelming. That’s why we connect patients to a cancer care advocate who can offer counseling to patients and their families. We’re here to help cancer patients navigate all their financial concerns so that bills never pile up. We offer support and financial guidance to all our patients so they can find programs, resources and solutions to alleviate the stress of paying for treatment while potentially reducing or eliminating the cost.",
            bodyTextLine2: "Our cancer care advocates can provide information on specialized programs and services at both the national and local level that are designed to help make it easier for patients to access financial assistance. Rest assured that at OptumCare Cancer Center, patients and their families have a knowledgeable and supportive team to guide them through insurance verifications and various financial processes.",
            customDivClass:'cards-no-border col-md-8 col-sm-12 pt-5',
            customSectionClass:'container-fluid',

        }
    ],

    textSection2: [
        {
            title2: "Cancer Center",
            bodyTextLine1: "This is where individualized care begins—with a vast array of resources. The OptumCare® Cancer Center was built in a way that is both holistic and patient-focused. Our state-of-the-art, 55,000-square-foot facility is to receive innovative treatment options for a wide range of oncologic malignancies. We provide a wide range of medical and supportive services including:",
            customDivClass:'cards-no-border col-md-8 col-sm-12 pt-5',
            customSectionClass:'container-fluid',

        }
    ],

    interiorSection: [
        {
            htmlCopy: `
                        <ul>
                            <li>Medical oncology</li>
                            <li>Infusion suite</li>
                            <li>Imaging</li>
                            <li>Breast care</li>
                            <li>Clinical research</li>
                            <li>Genetic counseling</li>
                            <li>Palliative care</li>
                            <li>Patient resource library</li>
                            <li>Café java</li>
                            <li>Auditorium</li>
                        </ul> `,
            imageUrl: image1,
            imageHeight: "",
            imageLeft: true,
            id: 1

        }
    ],

    section2: [
        {
                imageUrl: '',
                sectionHeader1: "Café Java",
                htmlCopy1: `<p>Our relaxing café within the cancer center is the perfect place for a pick-me-up. Here you can enjoy a wide selection of drinks and snacks at Café Java. We offer fresh ground coffee and an assortment of delicious beverages. We also offer fresh snacks and healthy treats because we understand the power of good nutrition. Grab a bite to eat or something to drink and enjoy conversation with friends and loved ones.</p> <br>`,
                sectionId1: "patient-resources-1",
                sectionHeader2: "Patient resource library",
                htmlCopy2: `<p>Within the cancer center, we offer a free resource center for patients and their families. Here patients can learn more about the many aspects of cancer care, from treatments to supportive therapies. These free materials and tools range from books on cancer types to assistance program listings and more. We even have cookbooks to educate and inspire patients as nutrition and maintaining an appetite is an important part of keeping strong during treatment.</p>
                            <p>Computers are available for patients and families to visit credible websites that can supplement the information provided by their cancer care team. We believe in education and giving our patients and their loved ones all the tools and support they need.</p>
                            <p>All services are coordinated and your complete care plan is customized to you. We know your time is valuable. That why our services at Cancer Center are all integrated so you can receive all the care you need in one place.</p>`,

                id: 3

        }
    ],

    buttons: [

    ],

    ctaInfo : {
        title: 'Member support',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default PATIENT_RESOURCES_GENERAL_DATA;