import cancerswetreat1 from '../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../img/Cancer-Care-Images/cancers-we-treat-9.png';
import cancerswetreat5 from '../../img/Cancer-Care-Images/cancer-we-treat-10.png';
import cancerswetreat6 from '../../img/Cancer-Care-Images/cancers-we-treat-closeface.png';
import cancerswetreat7 from "../../img/Cancer-Care-Images/cancer_we_treat_.jpg";
import cancerswetreat8 from "../../img/Cancer-Care-Images/cancer_we_treat_2.jpg";

const CANCERS_WE_TREAT_DATA = {
    sections: [
        {
            imageUrl: cancerswetreat6,
            sectionHeader1: "Bladder cancer",
            sectionCopy1: "The bladder is located in the pelvis and is a part of the urinary tract. It’s an expandible organ that stores urine before it exits the body. Bladder cancer develops when healthy cells alter to form a tumor. Bladder cancer can be non-muscle invasive or muscle invasive depending on how the cancer has penetrated the muscles of the bladder. The most common type is urothelial carcinoma. Risk factors include smoking, workplace exposure to certain chemicals, certain medicines, not drinking enough water, race, age, medical history and more. Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, radiation, chemotherapy, etc. Learn more about the types of treatment we offer.",
            sectionId1: "bladder-cancer",
            sectionHeader2: "Bone cancer",
            sectionCopy2: "The human body is made of 206 bones. Bones protect the organs, hold the body together, and allow movement with ease. Bone cancer can develop in any part of the bone but is most common in the long bones of the arms and legs. Sometimes cancers that begin in one part of the body, can spread to the bone. This type of cancer is called metastatic. Risk factors include genetic disorders, chondrosarcomas, chordomas, radiation, Paget’s disease and more. Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, radiation, targeted therapy, chemotherapy, etc. Learn more about the types of treatment we offer.",
            sectionId2: "bone-cancer",
            cssClass: "border-bottom",
            id: 1
        },
        {
            imageUrl: "",
            sectionHeader1: "Brain cancer",
            sectionCopy1: "The brain is the most complex organ in the body. It controls all vital functions including thinking, speech, movement and behavior. A brain tumor is a collection of abnormal cells in the brain. Brain cancer concerns can be overwhelming and frightening. We offer support as well as a trusted team of experts to ensure treatment is met with compassion and patient comfort in mind. The most common types of brain tumors are gliomas and non-glioma tumors depending on if the cancer develops in the glial cells or glial precursor cells. Symptoms include headaches, seizures, vision changes, memory loss and mood swings. Risk factors include radiation exposure, family history and a weakened immune system. Treatments are developed according to a patient’s stage and type of cancer, which can include chemotherapy, surgery, radiation therapy and more. Learn more about the types of treatment we offer.",
            sectionId1: "brain-cancer",
            sectionHeader2: "Breast cancer",
            sectionCopy2: "Breast cancer is the second most common form of cancer affecting women in the United States. Breast cancer develops when abnormal cells grow and multiply to form a tumor. The three main variations of breast cancer are hormone receptor positive, HER2 positive, and triple negative. General symptoms can include a lump, changes in the skin, breast, nipple or underarm, as well as pain and nipple discharge. Risk factors include being overweight, alcohol use, having children later in life, low levels of physical activity, birth control use, medical history and more. Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, targeted therapy, immunotherapy and more. Learn more about the types of treatment we offer.",
            sectionId2: "breast-cancer",
            cssClass: "border-bottom",
            id: 2
        },
        {
            imageUrl: cancerswetreat7,
            sectionHeader1: "Colorectal cancer",
            sectionCopy1: "The colon and rectum make up the largest intestine in the body. This part of the digestive system helps the body eliminate waste. Colorectal cancer often develops as a polyp, a noncancerous growth, on the inside of the colon or rectum of older adults. If undetected, they can become cancerous. The most common form of colorectal cancer is adenocarcinoma which affects the cells in the tissue that lines the colon and rectum. Symptoms include a change in bowel habits, rectal bleeding and abdominal discomfort or bloating. Risk factors include being overweight, not being physically active, certain diets high in red meat and processed meat, smoking, alcohol use, age, family history and more. Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, ablation therapy, radiation and more. Learn more about the types of treatment we offer.",
            sectionId1: "colorectal-cancer",
            sectionHeader2: "Kidney cancer",
            sectionCopy2: "The kidneys help the body filter blood and remove waste from the body. The kidneys control the production of red blood cells, help regulate the body, and produce hormones that regulate blood pressure. Kidney cancer often develops when abnormal cells grow and multiply to form a tumor in one or both kidneys. The most common type is renal cell carcinoma which affects the filtration system of the kidney, followed by urothelial which affects the bladder. There are over 30 subtypes of kidney cancer. The most common is clear cell. Understanding a patient’s type of cancer helps specialists create a personalized treatment plan. Symptoms include flank pain, trouble with urination and blood in the urine. Risk factors include smoking, obesity, high blood pressure, family history and more. Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, targeted therapy and more. Learn more about the types of treatment we offer.",
            sectionId2: "kidney-cancer",
            cssClass: "border-bottom",
            id: 3
        },
        {
            imageUrl: cancerswetreat8,
            sectionHeader1: "Leukemia ",
            sectionCopy1: "Leukemia is a type of cancer that affects the body's bone marrow, lymphatic system and white blood cells. Leukemia develops when blood cells change and affect the DNA’s control over the cells.  The mutations, or changes, cause white blood cells to grow abnormally without the ability to function properly. An imbalance of white blood cells can mean there are too few or too many being produced. The four main types of leukemia include: acute lymphocytic leukemia (ALL), acute myelogenous leukemia (AML), chronic lymphocytic leukemia (CLL) and chronic myelogenous leukemia (CML). Symptoms include fever and chills, fatigue, weight loss, frequent infections and easy bleeding or bruising. Risk factors include radiation and chemical exposure, certain viral infections and genetic syndromes and more. Treatments are developed according to a patient’s stage and type of cancer, which can include immunotherapy, chemotherapy, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId1: "leukemia",
            sectionHeader2: "Liver cancer",
            sectionCopy2: "Liver cancer develops when abnormal cells grow and multiply to form a tumor in the liver. The liver is often subject to metastatic cancer, which is when cancer in one organ or part of the body spreads to another. Metastatic cancer that affects the liver is not liver cancer. Instead, the cancer carries the name of the place where the cancer first developed. The main type of liver cancer is hepatocellular carcinoma (HCC). Cholangiocarcinoma affects the bile duct which is a tube that extends from the liver to the small intestine. Angiosarcoma is less common and develops in the liver’s blood vessels, grows rapidly and requires timely treatment. Symptoms include pain on upper right side, jaundice, nausea, vomiting and fatigue. The most common risk factors are chronic hepatitis B or C, race, age, alcohol use, cirrhosis and obesity. Treatments are developed according to a patient’s stage and type of cancer, which can include radiation therapy, ablation, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId2: "liver-cancer",
            cssClass: "border-bottom",
            id: 4
        },
        {
            imageUrl: cancerswetreat4,
            sectionHeader1: "Lung cancer",
            sectionCopy1: "With every breath, the lungs absorb fresh air to deliver oxygen to the bloodstream and the entire body. The lungs are made of mucus as well as nerve cells, blood cells, structural cells, and hormone producing cells. While smoking is the primary cause of lung cancer, anyone can develop it. The two main types of lung cancer are small cell lung and non-small cell lung cancer depending on what cells are affected. Lung cancer can develop anywhere in the lung when abnormal cells grow and multiply to form a tumor. Symptoms include persistent cough, shortness of breath, hoarseness, blood in sputum and weight loss. Risk factors include tobacco smoke, secondhand smoke, exposure to radon, asbestos or other cancer-causing agents, air pollution, family history and more. Treatments are developed according to a patient’s stage and type of cancer, which can include chemotherapy, radiation therapy, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId1: "lung-cancer",
            sectionHeader2: "Lymphoma",
            sectionCopy2: "Lymphoma cancer affects the lymphatic system. The lymphatic system is made of a network of lymph nodes, tissues, and organs that helps the body fight infection as well as rid the body of waste. Lymphoma develops when white blood cells grow and multiply out of control and form a tumor in the bone marrow, liver, spleen, tonsils or lymph nodes. B-cell lymphoma is the primary cause of most cases. The two main types of lymphoma Hodgkin lymphoma and non-Hodgkin lymphoma. Treatments vary accordingly depending on the subtypes. Symptoms include swelling of lymph nodes in neck and armpit, fatigue, fever, night sweats and weight loss. Risk factors include age, gender, race, family history, weakened immune system, autoimmune diseases, radiation exposure and certain infections. Treatments are developed according to a patient’s stage and type of cancer, which can include close monitoring, chemotherapy, stem cell transplant, immunotherapy, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId2: "lymphoma",
            cssClass: "border-bottom",
            id: 5
        },
        {
            imageUrl: "",
            sectionHeader1: "Myeloma",
            sectionCopy1: "Myeloma cancer affects plasma cells which are a type of white blood cell that boosts the immune system by helping the body fight infection. When these cancerous plasma cells grow and multiply out of control, they enable proteins to build up in the bone marrow and blood which can damage the body's regulation and negatively affect the organs and bone marrow. Symptoms include bone pain, nausea, constipation, metal fog and frequent infections. Risk factors include age, sex, race, family history, monoclonal gammopathy of undetermined significance (MGUS) and more. Treatments are developed according to a patient’s stage and type of cancer, which can include chemotherapy, radiation therapy, targeted therapy, immunotherapy, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId1: "myeloma",
            sectionHeader2: "Oral, head, and neck cancer",
            sectionCopy2: "The oral cavity along with the surrounding structures of the head and neck allow the body to eat, breath, and speak. Oral, head and neck cancer describe a wide range of cancers that develop in the throat, tongue, tonsils, gums, mouth, larynx, nose and sinuses. The five main types of head and neck cancer are laryngeal and hypopharyngeal cancer, nasal cavity and paranasal sinus cancer, nasopharyngeal cancer, oral and oropharyngeal cancer and salivary gland cancer which develops in the salivary gland. Symptoms include a lump in nose, mouth or throat, difficulty swallowing, persistent coughing, headache and sore throat. Risk factors include alcohol and tobacco use, human papillomavirus (HPV), ultraviolet (UV) light exposure, gender, obesity, poor nutrition, age, radiation exposure, genetic syndromes and more. Treatments are developed according to a patient’s stage and type of cancer, which can include chemotherapy, radiation therapy, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId2: "oral-head-neck-cancer",
            cssClass: "border-bottom",
            id: 6
        },
        {
            imageUrl: cancerswetreat5,
            sectionHeader1: "Ovarian cancer",
            sectionCopy1: "The term “ovarian cancer” generally includes ovarian, fallopian tube, and peritoneum cancer. Because they are closely related and receive similar treatment, they are often grouped together. Most ovarian cancers begin in the outermost point of the fallopian tubes which can then spread to other parts of the reproductive system. Symptoms include abdominal bloating or swelling, frequent need to urinate, changes in bowel habits, and weight loss. Risk factors include getting older, obesity, taking hormone therapy after menopause, family history, having had breast cancer and more. Treatments are developed according to a patient’s stage and type of cancer, which can include targeted therapy, radiation therapy, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId1: "ovarian-cancer",
            sectionHeader2: "Pancreatic cancer",
            sectionCopy2: "Most pancreatic cancers are named based off where they began, usually in the exocrine or endocrine component. Adenocarcinoma is the most common form of pancreatic cancer. Because there is no way to screen for it, pancreatic cancer can often go undetected until it is in the later stages of cancer. Symptoms can include abdominal pain and unusual weight loss over a few months. Symptoms include abdominal pain, loss of appetite, jaundice, dark-colored urine and itchy skin. Risk factors include tobacco use, diabetes, being overweight, chronic pancreatitis, exposure to certain chemicals, age, family history and more. Treatments are developed according to a patient’s stage and type of cancer, which can include chemotherapy, immunotherapy, ablation, surgery, etc. Learn more about the types of treatment we offer.",
            sectionId2: "pancreatic-cancer",
            cssClass: "border-bottom",
            id: 7
        },
        {
            imageUrl: "",
            sectionHeader1: "Prostate cancer",
            sectionCopy1: "The prostate is a gland located between the base of the penis and the rectum. It surrounds the urethra which transports urine and semen through the penis. Prostate cancer develops when healthy cells in the prostate transform and mutate into abnormal cells. Malignant or cancerous tumors can spread to other parts of the body and affect the functions of the prostate. Unlike most cancers, prostate cancer tends to develop and spread slowly. Symptoms can often go undetected for years. Risk factors include age, family history, geography, inherited gene changes and more. For the best treatment, doctors recommend closely monitoring the growth of the cancer to ensure the most favorable outcome possible. Symptoms include trouble urinating, blood in urine, bone pain and erectile dysfunction. Treatments are developed according to a patient’s stage and type of cancer, which can include chemotherapy, cryoablation, immunotherapy, targeted therapy, surgery, etc. Learn more about the types of treatment we offer. ",
            sectionId1: "prostate-cancer",
            sectionHeader2: "Skin cancer",
            sectionCopy2: "The skin is the largest organ of the body. Skin cancer is the most common form of cancer affecting over 3 million Americans every year. If detected early, it can be treated topically or by a dermatologist, which is a doctor who specializes in skin conditions and their treatments. More advanced skin cancer requires an integrated and comprehensive care team. Our providers work with patients to ensure treatments meet their needs and goals. The four main types of skin cancer include: basal cell carcinoma, squamous cell carcinoma, Merkel cell cancer and melanoma. Symptoms include a mole or spot that changes in size, bleeds or has pain. Risk factors include ultraviolet (UV) light exposure, having light colored skin, age, radiation exposure, smoking, weakened immune system, family history and more. Symptoms include Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, immunotherapy, targeted therapy, radiation, etc. Learn more about the types of treatment we offer.",
            sectionId2: "skin-cancer",
            cssClass: "border-bottom",
            id: 8
        },
        {
            imageUrl: "",
            sectionHeader1: "Testicular cancer",
            sectionCopy1: "Testicular cancer affects the testicles which are part of the male reproductive system. Men generally have two which are located at the base of the penis. Testicular cancer develops when healthy cells in the testicles transform and mutate into abnormal cells. The earlier it is detected, the easier it is to cure. It is still curable even in the later stages. The two main categories of testicular cancer are seminoma and non-seminoma depending on the cells affected. Seminoma grows and spreads more quickly than non-seminoma, and all types are treated differently. Symptoms include a lump or enlarged testicle, dull ache in abdomen or groin and back pain. Risk factors include undescended testicle, HIV infection, age, race, family history, height and more. Treatments are developed according to a patient’s stage and type of cancer, which can include surgery, chemotherapy, radiation therapy, etc. Learn more about the types of treatment we offer.",
            sectionId1: "testicular-cancer",
            sectionHeader2: "Thyroid cancer",
            sectionCopy2: "Thyroid cancer affects the thyroid gland. This gland has two lobes and is located towards the front of the neck below the voice box. It is three times more common in women than men and features the common symptom of a lump or swelling of the neck. Thyroid hormone regulates the metabolism and affects weight, heart rate, body temperature, digestion and much more. Symptoms include a lump or nodule in neck, difficulty swallowing, pain in throat and hoarseness. Risk factors include age, gender, genetics, family history, radiation, being overweight, iodine in the diet and more. The most common treatment is surgery which can be used in combination with other therapies to aid in treatment based on a patient’s unique needs and goals. Learn more about the types of treatment we offer.",
            sectionId2: "thyroid-cancer",
            cssClass: "border-bottom",
            id: 9
        }
    ],
    buttons: [
        {
            id: 1,
            buttonCopy: "Bladder cancer",
            buttonLink: "#bladder-cancer"
        },
        {
            id: 2,
            buttonCopy: "Bone cancer",
            buttonLink: "#bone-cancer"
        },
        {
            id: 3,
            buttonCopy: "Brain cancer",
            buttonLink: "#brain-cancer"
        },
        {
            id: 4,
            buttonCopy: "Breast cancer",
            buttonLink: "#breast-cancer"
        },
        {
            id: 5,
            buttonCopy: "Colorectal cancer",
            buttonLink: "#colorectal-cancer"
        },
        {
            id: 6,
            buttonCopy: "Kidney cancer",
            buttonLink: "#kidney-cancer"
        },
        {
            id: 7,
            buttonCopy: "Leukemia",
            buttonLink: "#leukemia"
        },
        {
            id: 8,
            buttonCopy: "Liver cancer",
            buttonLink: "#liver-cancer"
        },
        {
            id: 9,
            buttonCopy: "Lung cancer ",
            buttonLink: "#lung-cancer "
        },
        {
            id: 10,
            buttonCopy: "Lymphoma",
            buttonLink: "#lymphoma"
        },
        {
            id: 11,
            buttonCopy: "Myeloma",
            buttonLink: "#myeloma"
        },
        {
            id: 12,
            buttonCopy: "Oral, head, and neck cancer",
            buttonLink: "#oral-head-neck-cancer"
        },
        {
            id: 13,
            buttonCopy: "Ovarian cancer",
            buttonLink: "#ovarian-cancer"
        },
        {
            id: 14,
            buttonCopy: "Pancreatic cancer",
            buttonLink: "#pancreatic-cancer"
        },
        {
            id: 15,
            buttonCopy: "Prostate cancer",
            buttonLink: "#prostate-cancer"
        },
        {
            id: 16,
            buttonCopy: "Skin cancer",
            buttonLink: "#skin-cancer"
        },
        {
            id: 17,
            buttonCopy: "Testicular cancer",
            buttonLink: "#testicular-cancer"
        },
        {
            id: 18,
            buttonCopy: "Thyroid cancer",
            buttonLink: "#thyroid-cancer"
        }

    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default CANCERS_WE_TREAT_DATA;