import React from 'react';
import FOOTER_AUX_DATA from './footer-aux.data'
import './footer-aux.styles.scss'

const data = FOOTER_AUX_DATA

const FooterAuxComponent = ({imageUrl, linkUrl, bodyCopy, title, id, buttonText, columnQuarter,isBlank}) => (

        <div className="row footer-aux">
            <div className="col-md-4 col-sm-12 no-padding mobile-first">
              <h4>{data.section1.title}</h4>
              <ul className="top-footer-menu">
                  {
                      data.section1.items.map( ({id, title, link}) => (
                          <li key={id}><a href={link} target="_blank">{title}</a></li>
                      ))
                  }
              </ul>
            </div>
            {/*<div className="col-md-4 col-sm-12 no-padding">*/}
            {/*    <h4>{data.section2.title}</h4>*/}
            {/*    <ul>*/}
            {/*    {*/}
            {/*        data.section2.items.map( ({id, title, link}) => (*/}
            {/*            <li key={id}><a href={link} target="_blank">{title}</a></li>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*    </ul>*/}
            {/*</div>*/}
            <div className="col-md-4 col-sm-12 no-padding mobile-last">
                <h4>{data.section3.title}</h4>
                <ul>
                {
                    data.section3.items.map( ({id, title, link}) => (
                        <li key={id}><a href={link} target="_blank">{title}</a></li>
                    ))
                }
                </ul>
            </div>
        </div>
)

export default FooterAuxComponent;
