import image1 from "../../img/Cancer-Care-Images/homepage-5050-1.png";
import image2 from "../../img/Cancer-Care-Images/homepage-5050-2.png";
import image3 from "../../img/Cancer-Care-Images/homepage-5050-3.png";
import galleryimage4 from "../../img/galleryimage4.png";
import galleryimage3 from "../../img/Cancer-Care-Images/homepage-providers-small.png";
import galleryimage2 from "../../img/Cancer-Care-Images/homepage-informed-small.png";
import galleryimage1 from "../../img/Cancer-Care-Images/homepage-services-small.png";

const HOMEPAGE_DATA = {
    interiorSections: [
        {
            title: "Why choose Optum Care Cancer Care?",
            bodyCopy: "We’re dedicated to supporting you through every step. Optum Care Cancer Care is where compassion meets service. We’ve created a highly integrated program where all specialties collaborate as one team to care for you. Your team consists of oncologists, surgeons, radiologists, doctors, nurses, specialists, researchers, nutritionists, social workers and cancer care advocates, led by your primary oncologist. They’re all here to guide you and offer any support you need. Our purpose is to bring the right care and resources and handle all the details so you can focus your attention on staying healthy and strong each day.",
            imageUrl: image1,
            id: 1
        },
        {
            title: "Care that’s built on collaboration and integration",
            bodyCopy: "The right care should be easy to access and coordinated. That’s why our team and services are integrated so patients have everything they need while in our care. We have spent time building partnerships with other specialties and non-Optum partners within the market to bring even more convenient services to our patients. Whether needing a consult from another specialist or getting imaging done, patients have full access with Optum Care Cancer Care.",
            imageUrl: image2,
            imageLeft: true,
            id: 2
        },
        {
            bodyCopy: 'From the minute you walk through our doors, you’ll feel a sense of support and belonging. Our clinics and practice culture are built for comfort and peace of mind. Our priority is to promote healing and recovery for all our patients.',
            imageUrl: image3,
            id: 3
        }
    ],
    galleryItems : [
        {
            title: 'Informed innovation',
            imageUrl: galleryimage2,
            bodyCopy: 'Our tumor board is the only in-house program in Las Vegas. This multi-specialty group of 30 physicians come together to review radiologic and pathologic findings and discuss treatment plans using the latest in evidence-based medicine. Optum Care Cancer Care offers clinical trials from the National Cancer Institute and pharmaceutical sponsors, for many types of cancers.',
            buttonText: 'Clinical trials',
            linkUrl: '/clinical-trials',
            id: 2
        },
        {
            title: 'Locate a provider',
            imageUrl: galleryimage3,
            bodyCopy: "Our specialists are experts in their field but they don’t just dictate care. Rather, they work with each patient to create a personalized care plan. And we know getting started on a treatment plan is a top priority, which is why we see patients as soon as possible after referral.",
            buttonText: 'Locate a provider',
            linkUrl: '/providers',
            id: 3
        },
        {
            title: 'Additional support',
            imageUrl: galleryimage1,
            bodyCopy: "Your journey is unique. That's why we have a variety of services to help you as you navigate this time in your life. Learn about emotional support advocates, financial support services and more. Our goal is to keep you informed, health and supported every step of the way.",
            buttonText: 'Explore patient resources',
            linkUrl: '/patient-resources-general',
            id: 1
        }
        // {
        //     title: 'Patient resources',
        //     imageUrl: galleryimage4,
        //     bodyCopy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        //     buttonText: 'New patient information',
        //     linkUrl: '#',
        //     id: 4

        // }
    ],
    textSection: {
        title: 'Serving Las Vegas through orthopaedic excellence',
        customTitle1Class: 'dash',
        bodyTextLine1:'We offer highly specialized orthopaedic care in a professional and friendly setting. Our focus is delivering outstanding care to every patient experiencing orthopaedic-related challenges and injuries. We approach each patient with compassion and understanding.',
        bodyTextLine2: 'As our patient, we’ll review all your options and provide educational resources to develop a treatment plan personalized to your condition. We’re committed to providing you with the highest quality of care and treatment available.',
        customSectionClass: 'container-fluid col-12 col-lg-8'
    },

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}

export default HOMEPAGE_DATA;
