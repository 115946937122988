import React from 'react';
import ProviderThumbnail from "./provider-thumbnail/provider-thumbnail.component";
import "./provider-gallery.styles.scss"
import {connect} from "react-redux";
import { createStructuredSelector } from 'reselect';
import { selectProviderSections } from "../../redux/provider/provider.selectors";
import DividerArrowDownComponent from "../divider-arrow-down/divider-arrow-down.component";
import { getProvidersBySpecialty } from "../../main";

const ProviderGallery = ({ sections: providers }) => {

    var generalOrthopedics = getProvidersBySpecialty('General Orthopaedics');

    var medicalOncology = getProvidersBySpecialty('Medical Oncology');

    var hematology = getProvidersBySpecialty('Hematology');

    var radiationOncology = getProvidersBySpecialty('Radiation Oncology');

    var internalMedicine = getProvidersBySpecialty('Internal Medicine');

    var generalSurgery = getProvidersBySpecialty('General Surgery');

    var breastCare = getProvidersBySpecialty('Breast Care');




    return (
        <div className="provider-gallery-component">
            {/*<DividerArrowDownComponent title="Our Doctors" divId="general-orthopaedics"/>*/}
            {/*<div className="row gallery-row centered-div">*/}
            {/*    {*/}
            {/*        generalOrthopedics.map(({id, ...otherSectionProps}) => (*/}
            {/*            <ProviderThumbnail key={id} {...otherSectionProps} />*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</div>*/}

            <DividerArrowDownComponent title="Medical Oncology" divId="general-orthopaedics"/>
            <div className="row gallery-row centered-div">
                {
                    medicalOncology.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            <DividerArrowDownComponent title="Hematology" divId="general-orthopaedics"/>
            <div className="row gallery-row centered-div">
                {
                    hematology.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            <DividerArrowDownComponent title="Radiation Oncology" divId="general-orthopaedics"/>
            <div className="row gallery-row centered-div">
                {
                    radiationOncology.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            <DividerArrowDownComponent title="Internal Medicine" divId="general-orthopaedics"/>
            <div className="row gallery-row centered-div">
                {
                    internalMedicine.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            <DividerArrowDownComponent title="General Surgery" divId="general-orthopaedics"/>
            <div className="row gallery-row centered-div">
                {
                    generalSurgery.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>

            <DividerArrowDownComponent title="Breast Care" divId="general-orthopaedics"/>
            <div className="row gallery-row centered-div">
                {
                    breastCare.map(({id, ...otherSectionProps}) => (
                        <ProviderThumbnail key={id} {...otherSectionProps} />
                    ))
                }
            </div>
        </div>)
};

const mapStateToProps = createStructuredSelector({
    sections: selectProviderSections
});

export default connect(mapStateToProps)(ProviderGallery);
