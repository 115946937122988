import placeHolderMaleGrid from '../../img/figma/Male-Card.png';
import placeHolderFemaleGrid from '../../img/figma/Female-Card.png';

import femalePlaceholder from '../../img/figma/Female-Placeholder.png';
import malePlaceholder from '../../img/figma/Male-Placeholder.png';

import robertBeckgrid from '../../img/figma/grid/Beck_Robert_MD_grid.png';
import robertBeck from '../../img/figma/interior/Beck_Robert_MD.png';

import michaelBeckergrid from '../../img/figma/grid/Becker_Michael_DO_grid.png';
import michaelBecker from '../../img/figma/interior/Becker_Michael_DO.png';

import gregDeangrid from '../../img/figma/grid/Dean_Greg_MD_grid.png';
import gregDean from '../../img/figma/interior/Dean_Greg_MD.png';

import johnEllertongrid from '../../img/figma/grid/Ellerton_John_MD_grid.png';
import johnEllerton from '../../img/figma/interior/Ellerton_John_MD.png';

import janiceEnriquezgrid from '../../img/figma/cancer_care_doctors_grid/Enriquez_Janice_NP_grid.jpg';
import janiceEnriquez from "../../img/figma/interior/Janice_Eriquez.png";

import russellGollardgrid from '../../img/figma/cancer_care_doctors_grid/Gollard_Russell_MD_grid.jpg';
import russellGollard from "../../img/figma/interior/Russell_Gollard.png";

import JahangirKhawajaSaadgrid from '../../img/figma/cancer_care_doctors_grid/Jahangir_KhawajaSaad_MD_grid.jpg';
import KhawajaJahangir from "../../img/figma/interior/Khawaja_Jahangir.png";

import KurtzhalsPamelagrid from '../../img/figma/cancer_care_doctors_grid/Kurtzhals_Pamela_MD_grid.jpg';
import PamelaKurtzhals from "../../img/figma/interior/Pamela_Kurtzhals.png";

import carlosLopezgrid from '../../img/figma/grid/Lopez_Carlos_MD_grid.png';
import carlosLopez from '../../img/figma/interior/Lopez_Carlos_MD.png';

import cathrineConnorgrid from "../../img/figma/grid/Cathrine_Conner_grid.png";
import cathrineConnor from "../../img/figma/interior/Cathrine_Connor.png";

import ritchieStevensgrid from "../../img/figma/cancer_care_doctors_grid/ritchie_stevens_grid.png";
import ritchieStevens from "../../img/figma/interior/Ritchie_Stevens.png";

import karissaTrangrid from "../../img/figma/grid/karissa_tan_grid.png";
import karissaTran from "../../img/figma/interior/Karissa_Tan.png";

import rachaelTaylorgrid from "../../img/figma/grid/rachel_taylor_grid.png";
import rachaelTaylor from "../../img/figma/interior/Rachael_Taylor.png";

import beauToygrid from "../../img/figma/grid/bean_toy_gird.png";
import beauToy from "../../img/figma/interior/Beau_Toy.png";

import michelleTrappgrid from "../../img/figma/grid/michelleTrapp_grid.png";
import michelleTrapp from "../../img/figma/interior/Michelle_Trapp.png";

import jacqualineWebstergrid from "../../img/figma/grid/jacqueline_webster_grid.png";
import jacquelineWebster from "../../img/figma/interior/jacqueline_webster.png";

import edgardoFaylonaGrid from "../../img/figma/grid/edgardo_faylona_grid.png";
import edgardoFaylona from "../../img/figma/interior/edgardo_faylona.png";

import ericGershman from "../../img/figma/interior/Eric_Gershman.png";
import ericGershmanGrid from "../../img/figma/grid/eric_gershman_grid2.png";

import Salamat from "../../img/figma/interior/Arsalan_Salamat.png";
import Salamat_grid from "../../img/figma/grid/Arsalan_Salamatgrid.png";

const PROVIDERS_DATA = {
    sections: [
        {
            imageUrl: placeHolderMaleGrid,
            providerName: "Alex Makalinao",
            providerTitle: "MD",
            linkUrl: "alex-makalinao",
            providerInteriorImage: malePlaceholder,
            specialty: ["Medical Oncology", "Hematology", "Internal Medicine"],
            sex: "Male",
            providerBio: `Alex Makalinao, MD, is a board-certified in hematology and medical oncology. Dr. Makalinao focuses on putting his patients first by listening to their needs and addressing all concerns. He believes in patient education and shared decision making.  `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of the Philippines, College of Medicine in Manila, Philippines'
                },
                {
                    type: 'Internship',
                    name: 'State University of New York, Mercy Hospital of Buffalo Consortium'
                },
                {
                    type: 'Residency',
                    name: 'State University of New York, Mercy Hospital of Buffalo Consortium'
                },
                {
                    type: 'Fellowship',
                    name: "University of California, Irvine"
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 1
        },

        {
            imageUrl: Salamat_grid,
            providerName: "Arsalan Salamat",
            providerTitle: "MD",
            linkUrl: "arsalan-salamat",
            providerInteriorImage: Salamat,
            specialty: ["General Surgery"],
            sex: "Male",
            providerBio: `Arsalan Salamat, MD, is a board-certified breast surgeon and general surgeon. Dr. Salamat believes in listening to his patients to understand their unique health needs. From there, Dr. Salamat works with each patient to explore all of their options together as a team. `,
            providerPhilosophy: [],
            languages: ["English", "Farsi", "Spanish"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Illinois at Chicago College of Medicine'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: 'Presence Health St. Joseph Hospital in Chicago'
                },
                {
                    type: 'Fellowship',
                    name: "University of Pittsburgh Medical Center in Pittsburgh, PA "
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Society of Breast Surgery", "Society of Surgical Oncology", "American Medical Association", "International College of Surgeons", "American College of Surgeons"],
            publicationsAndResearch: [],
            providerLocations: [
                1, 3, 6, 3
            ],
            id: 2
        },

        {
            imageUrl: beauToygrid,
            providerName: "Beau J. Toy",
            providerTitle: "MD",
            linkUrl: "beau-toy",
            providerInteriorImage: beauToy,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Beau J. Toy, MD, is a board-certified radiation oncologist. Dr. Toy loves helping people understand their conditions and how radiation therapy can help. He focuses on helping patients feel comfortable so that they can find solutions and the best treatment options together. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Harvard Medical School in Boston, MA'
                },
                {
                    type: 'Internship',
                    name: 'St. Joseph Mercy Hospital in Ann Arbor, MI'
                },
                {
                    type: 'Residency',
                    name: 'University of Michigan Medical Center in Ann Arbor, MI'
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Society for Therapeutic Radiology and Oncology", "American College of Radiology", "American Medical Association"],
            publicationsAndResearch: [],
            providerLocations: [
                1, 3, 6, 3
            ],
            id: 3
        },

        {
            imageUrl: carlosLopezgrid,
            providerName: "Carlos Lopez",
            providerTitle: "MD",
            linkUrl: "carlos-lopez",
            providerInteriorImage: carlosLopez,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Carlos Lopez, MD, is a board-certified radiation oncologist. Dr. Lopez is patient-focused and believes that good communication between providers and their patients is the key to optimal outcomes. Dr. Lopez treats patients as if they were members of his own family. `,
            providerPhilosophy: [],
            languages: ["English", "Spanish"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'New York University School of Medicine in New York, NY'
                },
                {
                    type: 'Internship',
                    name: 'University of Chicago in Chicago, IL '
                },
                {
                    type: 'Residency',
                    name: 'University of Michigan in Ann Arbor, MI'
                },
                {
                    type: 'Fellowship',
                    name: "University of Chicago in Chicago, IL"
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [
                1, 3, 6, 3
            ],
            id: 4
        },

        {
            imageUrl: placeHolderMaleGrid,
            providerName: "Foluso N. Ogunleye",
            providerTitle: "MD",
            linkUrl: "foluso-ogunleye",
            providerInteriorImage: malePlaceholder,
            specialty: ["Medical Oncology", "Hematology", "Internal Medicine"],
            sex: "Male",
            providerBio: `Foluso N. Ogunleye, MD, is a board-certified hematologist and medical oncologist. He also specializes in internal medicine. Dr. Ogunleye loves having the opportunity to improve the lives of his patients each day. He believes compassion is key to the proper delivery of medical care. He focuses on making sure patients feel comfortable and always make time to address patients concerns and questions.`,
            providerPhilosophy: [],
            languages: ["English", "Yoruba"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Benin School of Medicine in Benin, Nigeria'
                },
                {
                    type: 'Internship',
                    name: 'Roger William Medical Center, Boston University School of Medicine in Providence, RI'
                },
                {
                    type: 'Residency',
                    name: 'Roger William Medical Center, Boston University School of Medicine in Providence, RI'
                },
                {
                    type: 'Fellowship',
                    name: "William Beaumont Hospital, Oakland University William Beaumont school of Medicine in Royal Oak, MI"
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Society of Clinical Oncology", "American Society of Hematology", "American Medical Association"],
            publicationsAndResearch: [],
            providerLocations: [
                1, 3, 6, 3
            ],
            id: 6
        },


        //More providers to be added
        //Category/Specialty updates for gallery page coming as well
        {
            imageUrl: gregDeangrid,
            providerName: "Gregory Dean",
            providerTitle: "MD",
            linkUrl: "gregory-dean",
            providerInteriorImage: gregDean,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Gregory Dean, MD, is a board-certified radiation oncologist. Dr. Dean believes a good radiation oncologist is patient and listens carefully to what his patients need. He strives to find the best solutions with his patients and focuses on prevention and patient education. When his patients understand their options, they can work together to make informed decisions and develop treatments that are easier to follow and stick to.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Nevada School of Medicine in Las Vegas, NV'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: 'Northwestern Memorial Hospital in Chicago, IL'
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [
                1, 3, 6, 3
            ],
            id: 7
        },

        {
            imageUrl: placeHolderMaleGrid,
            providerName: "Irene Kyritsis",
            providerTitle: "APRN",
            linkUrl: "irene-kyritsis",
            providerInteriorImage: malePlaceholder,
            specialty: ["Medical Oncology"],
            sex: "Male",
            providerBio: `Irene Kyritsis, APRN, is an Advanced Practice Registered Nurse who practices medical oncology. Kyritsis believes there is nothing more rewarding than making a difference in her patients' lives. She focuses on educating her patients on their conditions and treatment options so that they feel informed and able to participate in decision-making regarding their health. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: ''
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: ''
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [
                1, 3, 6, 3
            ],
            id: 8
        },


        // {
        //     imageUrl: janiceEnriquezgrid,
        //     providerName: "Janice Enriquez",
        //     providerTitle: "CNM, APRN",
        //     linkUrl: "janice-enriquez",
        //     providerInteriorImage: janiceEnriquez,
        //     specialty: ["Breast Care"],
        //     sex: "Female",
        //     providerBio: `Janice Enriquez is a Certified Nurse-Midwives, CNM, and an Advance Practice Registered Nurse, APRN. Enriquez takes pride in being able to help women get closer to health each day. She focuses on getting to know her patients so that care is delivered with trust and understanding. `,
        //     providerPhilosophy: [],
        //     languages: ["English"],
        //     qualifications: [
        //         {
        //             type: 'Medical School',
        //             name: 'California State University in Fullerton, CA '
        //         },
        //         {
        //             type: 'Internship',
        //             name: ''
        //         },
        //         {
        //             type: 'Residency',
        //             name: ''
        //         },
        //         {
        //             type: 'Fellowship',
        //             name: ""
        //         },
        //         {
        //             type: 'Board certifications',
        //             name: ''
        //         }
        //     ],
        //     organizations: [],
        //     professionalAssociations: ["American College of Nurse-Midwives", "American College of Nurse-Midwives", "Nevada Chapter", "Association of Perioperative Registered Nurses"],
        //     publicationsAndResearch: [],
        //     providerLocations: [
        //         1, 3, 6, 3
        //     ],
        //     id: 11
        // },

        {
            imageUrl: johnEllertongrid,
            providerName: "John A. Ellerton ",
            providerTitle: "MD",
            linkUrl: "john-ellerton",
            providerInteriorImage: johnEllerton,
            specialty: ["Medical Oncology", "Hematology"],
            sex: "Male",
            providerBio: `John A. Ellerton, MD, is a board-certified medical oncologist. Dr. Ellerton has been a practicing physician for over 35 years. He is a firm beliver in the power of research and what it can do to improve outcomes for cancer patients.  He is closely involved in various clinical trials, which are the scientific backbone of oncology. He also leads a state-wide cancer control and treatment program, which has been funded by the National Cancer Institute for more than 30 years. Dr. Ellerton works to educate his patients so they know understand their options as well as their treatment plans. He is an advocate for cancer prevention and screening for early detection. He loves changing lives and working directly with his patients to create treatment plans tailored to their needs.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Mcgill University in Montreal, Quebec'
                },
                {
                    type: 'Internship',
                    name: 'Mcgill University in Montreal, Quebec '
                },
                {
                    type: 'Residency',
                    name: "St. Michael's Hospital University of Toronto in Ontario"
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [1],
            id: 12
        },

        {
            imageUrl: karissaTrangrid,
            providerName: "Karissa Tan",
            providerTitle: "APRN",
            linkUrl: "karissa-tan",
            providerInteriorImage: karissaTran,
            specialty: ["Medical Oncology", "Hematology"],
            sex: "Male",
            providerBio: `Karissa Tan, APRN, is a certified Advanced Practice Registered Nurse who practices hematology and medical oncology. Tan strives to bring compassion and kindness to every encounter with her patients. Tan believes in helping patients make informed decisions and loves to share her knowledge and resources with them. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Medicine and Dentistry of New Jersey, Newark, NJ'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: ''
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Nurses Association", "American Association of Neuroscience Nurses"],
            publicationsAndResearch: [],
            providerLocations: [1],
            id: 14
        },

        {
            imageUrl: JahangirKhawajaSaadgrid,
            providerName: "Khawaja Jahangir",
            providerTitle: "MD",
            linkUrl: "khawaja-jahangir",
            providerInteriorImage: KhawajaJahangir,
            specialty: ["Medical Oncology", "Internal Medicine"],
            sex: "Male",
            providerBio: `Khawaja Jahangir, MD, is board-certified in internal medicine and medical oncology. Dr. Jahangir brings his extensive knowledge and passion for health care to OptumCare Cancer Care as a research director. He firmly believes that the best way to fight cancer is through education, clinical trials, and research surrounding new treatments to find the best solutions for his patients. `,
            providerPhilosophy: [],
            languages: ["English", "Urdu", "Punjabi"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Ross University in Bridgetown, Barbados'
                },
                {
                    type: 'Internship',
                    name: 'SUNY at Syracuse, in New York'
                },
                {
                    type: 'Residency',
                    name: 'University of Nevada in Reno, NV'
                },
                {
                    type: 'Fellowship',
                    name: "Louisiana State University in Baton Rouge, LA"
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 15
        },

        {
            imageUrl: placeHolderMaleGrid,
            providerName: "Mary Esele",
            providerTitle: "APRN",
            linkUrl: "mary-esele",
            providerInteriorImage: malePlaceholder,
            specialty: ["Breast Care"],
            sex: "Male",
            providerBio: `Mary Esele is an Advanced Practice Registered Nurse, APRN, and a Doctor of Nursing Practice, DNP, who specializes in breast care.  Esele is passionate about patient education and prides herself on her ability to establish clear lines of communication between patients and their family. She believes the best treatment plans are a teamwork effort. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Doctoral Degree at South University in Tampa, FL'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: ''
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 16
        },

        {
            imageUrl: michaelBeckergrid,
            providerName: "Michael Becker",
            providerTitle: "DO",
            linkUrl: "michael-becker",
            providerInteriorImage: michaelBecker,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Michael Becker, DO, is a board-certified Doctor of Osteopathic Medicine who specializes in radiation oncology. Dr. Becker understands the importance of trust when establishing a doctor-patient relationship and makes sure to address all questions and concerns when explaining a new or difficult situation.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Des Moines University, in Des Moines, IA'
                },
                {
                    type: 'Internship',
                    name: 'San Antonio Uniformed Services Health Education Consortium, Brooke Army Medical Center in San Antonio, TX '
                },
                {
                    type: 'Residency',
                    name: 'Wayne State University, Detroit Medical Center in Detroit, MI '
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [2],
            id: 17
        },

        {
            imageUrl: michelleTrappgrid,
            providerName: "Michelle Trapp",
            providerTitle: "APRN",
            linkUrl: "michelle-trapp",
            providerInteriorImage: michelleTrapp,
            specialty: ["Breast Care"],
            sex: "Female",
            providerBio: `Michelle Trapp is an Advanced Practice Registered Nurse, APRN, specializing in breast care. Trapp is certified with the American Midwifery Education Board and forms bonds with patients by listening to their needs and prioritizing their comfort and safety. Trapp believes understanding her patients is just as important as the treatment they receive.`,
            providerPhilosophy: [],
            languages: ["English", "Spanish"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'California State University, Fullerton  '
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: ''
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 18
        },

        {
            imageUrl: KurtzhalsPamelagrid,
            providerName: "Pamela L. Kurtzhals",
            providerTitle: "MD",
            linkUrl: "pamela-kurtzhals",
            providerInteriorImage: PamelaKurtzhals,
            specialty: ["Breast Care", "General Surgery"],
            sex: "Male",
            providerBio: `Pamela L.Kurtzhals, MD, is a board-certified general surgeon and breast surgeon. Dr. Kurtzhals believes in individualized patient care and focuses on tailoring treatment plans according to their specific needs and desires. Her compassionate approach builds relationships based on trust and communication in order to put patients at ease.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'Wayne State University in Detroit, MI '
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: 'Loyola University Medical Center in Hines, IL '
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 19
        },

        {
            imageUrl: rachaelTaylorgrid,
            providerName: "Rachael Taylor",
            providerTitle: "APRN",
            linkUrl: "rachael-taylor",
            providerInteriorImage: rachaelTaylor,
            specialty: ["Medical Oncology", "Hematology"],
            sex: "Male",
            providerBio: `Rachael Taylor, APRN, is an Advanced Practice Registered Nurse specializing in hematology and medical oncology. Taylor loves providing personalized care and advocating for her patients. She focuses on understanding her patients' needs so she can share her knowledge and resources with them. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Nevada in Las Vegas, NV'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: ''
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Academy of Nurse Practitioners "],
            publicationsAndResearch: [],
            providerLocations: [7,8],
            id: 20
        },

        {
            imageUrl: ritchieStevensgrid,
            providerName: "Ritchie Stevens",
            providerTitle: "MD",
            linkUrl: "ritchie-stevens",
            providerInteriorImage: ritchieStevens,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Ritchie Stevens, MD, is a board-certified radiation oncology specialist. Dr. Stevens believes in taking a compassionate approach when discussing options and listens to his patients’ concerns. He focuses on working directly with his patients as a team to develop treatment plans that can maximize a patient’s results.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Colorado School of Medicine in Boulder, CO'
                },
                {
                    type: 'Internship',
                    name: ''
                },
                {
                    type: 'Residency',
                    name: 'University of California, Irvine'
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [5],
            id: 21
        },

        {
            imageUrl: robertBeckgrid,
            providerName: "Robert Beck",
            providerTitle: "MD",
            linkUrl: "robert-beck",
            providerInteriorImage: robertBeck,
            specialty: ["Radiation Oncology"],
            sex: "Male",
            providerBio: `Robert Beck, MD, is a board-certified radiation oncologist. Dr. Beck believes in providing care that is both compassionate and individualized. He strives to educate and empower his patients so they feel confident when it comes to decision making regarding their own health. Dr. Beck treats patients as if they were a part of his family. `,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Utah in Salt Lake City, UT '
                },
                {
                    type: 'Internship',
                    name: 'University of Kentucky in Lexington KY '
                },
                {
                    type: 'Residency',
                    name: 'Rutgers University in New Brunswick, NJ'
                },
                {
                    type: 'Fellowship',
                    name: ""
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: [],
            publicationsAndResearch: [],
            providerLocations: [5,6],
            id: 22
        },

        {
            imageUrl: russellGollardgrid,
            providerName: "Russell P. Gollard",
            providerTitle: "MD",
            linkUrl: "russell-gollard",
            providerInteriorImage: russellGollard,
            specialty: ["Medical Oncology", "Hematology", "Internal Medicine"],
            sex: "Male",
            providerBio: `Russell P. Gollard, MD, is a board-certified medical oncologist and hematologist. For over 20 years, Dr. Gollard has worked to help others and improve their quality of care. As medical director of OptumCare Cancer Care, Dr. Gollard remains committed to his lifelong goal of improving cancer care for all. He seeks to build relationships based on trust by focusing on communication, education, and respect, to ensure optimal care is delivered in a way that is easy to understand. Dr. Gollard believes in patient education as well as participation and involvement in research. He prides himself on being up to date on the most recent scientific advances.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Southern California School of Medicine, Los Angeles, CA'
                },
                {
                    type: 'Internship',
                    name: 'University of Southern California Medical Center, Los Angeles, CA'
                },
                {
                    type: 'Residency',
                    name: 'University of Southern California Medical Center, Los Angeles, CA '
                },
                {
                    type: 'Fellowship',
                    name: "Scripps Clinic and Research Foundation in La Jolla, CA"
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Society of Internal Medicine", "American College of Physicians-Fellow, Clark County Medical Society", "Nevada State Medical Association", "American Association of Cancer Research", "American Medical Association", "American Society of Clinical Oncology", "American Society of Hematology"],
            publicationsAndResearch: [],
            providerLocations: [7,8],
            id: 23
        },

        {
            imageUrl: placeHolderMaleGrid,
            providerName: "Carlos Araujo",
            providerTitle: "MD",
            linkUrl: "carlos-araujo",
            providerInteriorImage: malePlaceholder,
            specialty: ["Medical Oncology", "Hematology"],
            sex: "Male",
            providerBio: `Carlos Araujo, MD, is a board-certified medical oncologist and hematologist. Dr. Araujo believes in a multidisciplinary approach to care. He focuses on working closely with his team to present his patients with options, information, and the time they need to ask questions. He prides himself in being an active listener and makes sure to put his patients first. `,
            providerPhilosophy: [],
            languages: ["English", "Portuguese", "Spanish"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of Illinois at Chicago College of Medicine '
                },
                {
                    type: 'Internship',
                    name: 'University of Chicago Medical Center in Chicago, IL  '
                },
                {
                    type: 'Residency',
                    name: 'Lutheran General Hospital in Park Ridge, IL '
                },
                {
                    type: 'Fellowship',
                    name: "University of Colorado Health Sciences Center in Denver, CO "
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Society of Clinical Oncology", "Clark County Medical Society"],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 24
        },

        {
            imageUrl: edgardoFaylonaGrid,
            providerName: "Edgardo Faylona",
            providerTitle: "MD",
            linkUrl: "edgardo-faylona",
            providerInteriorImage: edgardoFaylona,
            specialty: ["Hematology"],
            sex: "Male",
            providerBio: `Dr. Faylona has worked in oncology for over three decades. Dr. Faylona chose oncology because he felt it was the most challenging field in medicine and one that is evolving rapidly. He is actively engaged in new oncology treatments that involve non-chemotherapy drugs.`,
            providerPhilosophy: [],
            languages: ["English"],
            qualifications: [
                {
                    type: 'Medical School',
                    name: 'University of the Philippines '
                },
                {
                    type: 'Internship',
                    name: 'University of the Philippines-Philippine General Hospital '
                },
                {
                    type: 'Residency',
                    name: 'Ravenswood Hospital Medical Center'
                },
                {
                    type: 'Fellowship',
                    name: "Indiana University Hospital Medical Center "
                },
                {
                    type: 'Board certifications',
                    name: ''
                }
            ],
            organizations: [],
            professionalAssociations: ["American Board of Internal Medicine"],
            publicationsAndResearch: [],
            providerLocations: [8],
            id: 25
        },





    ],
    buttons: []
}

export default PROVIDERS_DATA;

