import cancerswetreat1 from '../../../img/cancers-we-treat-1.png';
import cancerswetreat2 from '../../../img/cancers-we-treat-2.png';
import cancerswetreat3 from '../../../img/cancers-we-treat-3.png';
import cancerswetreat4 from '../../../img/cancers-we-treat-4.png';

const MEDICAL_ONCOLOGY_DATA = {
    sections: [
        {
            imageUrl: "",
            sectionHeader1: "",
            htmlCopy1: `<p>As the backbone of cancer care, medical oncology deals with the full spectrum of treatment and therapies. A medical oncologist is the lead care provider for a cancer patient. They diagnose and treat cancer while providing guidance to the patient's entire care team. Medical oncologists work directly with patients to monitor their treatment and lead the care team throughout their journey. They coordinate care among specialists and other care providers and manage referrals to nutritional and other services.</p>
                        <p>Our oncology specialists are here to offer patients all their options while helping them better understand their cancer and treatment plan. Our highly coordinated cancer care teams are staffed by compassionate and specialized providers.</p>`,
            sectionId1: "medical-oncology-1",
            id: 1
        },
    ],

    ctaInfo : {
        title: 'Schedule a consultation',
        htmlCopy: `<h3 class='dash'>Schedule a consultation</h3>
                    <p>
                    For any cancer related conditions, you can make an appointment by calling
                    <br/><br/>
                    <a href='tel:+17027248787'>1-702-724-8787</a>, <b>TTY 711</b>
                    <br/><br/>
                    Monday–Friday, 8 a.m–5 p.m.
                    </p>
                  `
    }
}; 

export default MEDICAL_ONCOLOGY_DATA; 
