import React from 'react';
import { withRouter } from 'react-router-dom';
import './locations-row.styles.scss';

const LocationsRowComponent = ( {title, id, address1, address2, address3, phone, fax, linkUrl, mapUrl } ) => (
    <section className="container-large">
            <div className="row">
                <div className="col-md-6">
                    <h2>{title}</h2>
                    <p>{address1}<br/>
                        {address2}<br/>
                        {address3}</p>
                    <p>
                        <a href={`${linkUrl}`}
                           target="_blank" rel="noreferrer">Map it <i className="fas fa-external-link-alt no-underline"></i></a></p>
                    <p><b>Contact Info</b><br/>
                    Phone: <a href={`tel:${phone}`}>{phone}</a><br/>
                        Fax: {fax}</p>
                </div>

                <div className="col-md-6 map-locations-page mb-5">
                    <iframe title="Map of location" width="100%" height="400" loading="lazy" frameborder="0" className="pull-right" allowFullScreen src={mapUrl}></iframe>
                </div>
            </div>
    </section>

)

export default withRouter(LocationsRowComponent);
